import { datadogRum } from "@datadog/browser-rum";
import {
  HTTPHeaders,
  TRPCClientError,
  createTRPCProxyClient,
  httpLink,
  CreateTRPCProxyClient,
} from "@trpc/client";
import { FetchEsque } from "@trpc/client/dist/internals/types";
import { CreateTRPCNext, createTRPCNext } from "@trpc/next";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import { fetchWithProjectSession } from "lib/project-sessions/fetch";
import { isFunction, isNumber, isObject, isString } from "lodash";
import { NextPageContext } from "next";
import {
  isCancelledError,
  MutationCache,
  QueryCache,
} from "@tanstack/react-query";
import { config } from "@/config";
import { AppRouter } from "server/routers/app";
import { relogin, shouldRelogin } from "lib/utils/auth";
import { toastFriendlyErrorMessage } from "lib/getFriendlyErrorMessage";
import { isPublicPage } from "./isPublicPage";
import { isTablesApiError } from "server/services/tables/types/type-helpers";
import { handleTablesAPIError } from "lib/utils/handleTablesError";
import { ProjectSessionExpiredModal } from "components/SessionExpiredModal";
import { ProjectWithExtra } from "@/server/schemas/projects";
import { isTrpcProjectSessionExpiredError } from "@/server/types/errors";
import { isProjectSessionExpiredError } from "@/server/openapi/errors";

/**
 * Extend `NextPageContext` with meta data that can be picked up by `responseMeta()` when server-side rendering
 */
export interface SSRContext extends NextPageContext {
  /**
   * Set HTTP Status code
   * @usage
   * const utils = trpc.useUtils();
   * if (utils.ssrContext) {
   *   utils.ssrContext.status = 404;
   * }
   */
  status?: number;
}

/**
 * tRPC client for making requests in React components.
 *
 * see https://trpc.io/docs/client/nextjs
 */
export const trpc: CreateTRPCNext<AppRouter, SSRContext> = createTRPCNext<
  AppRouter,
  SSRContext
>({
  config({ ctx }) {
    /**
     * If you want to use SSR, you need to use the server's full URL
     * @link https://trpc.io/docs/ssr
     */
    return {
      // Using the `httpLink` disables batching
      links: linksConfig(ctx),
      /**
       * @link https://react-query-v3.tanstack.com/reference/QueryClient
       */
      queryClientConfig: {
        defaultOptions: {
          queries: {
            throwOnError: true,
            retry(failureCount, error) {
              // Don't retry if it's a client error. Otherwise retry twice (for
              // a total of 3 requests).
              if (
                error instanceof TRPCClientError &&
                isNumber(error.data?.httpStatus) &&
                error.data?.httpStatus >= 400 &&
                error.data?.httpStatus < 500
              ) {
                return false;
              }
              return failureCount < 2;
            },
          },
        },
        mutationCache: new MutationCache({
          onError(error, _variables, _context, mutation) {
            if (
              error instanceof TRPCClientError &&
              isTablesApiError(error.data)
            ) {
              handleTablesAPIError(error);
            }

            if (
              isTrpcProjectSessionExpiredError(error) ||
              isProjectSessionExpiredError(error)
            ) {
              ProjectSessionExpiredModal.open();

              return;
            }

            if (isObject(mutation.meta)) {
              if (mutation.meta.noToast === true) {
                return;
              }
            }

            toastFriendlyErrorMessage(error);
          },
        }),
        queryCache: new QueryCache({
          onError(error, query) {
            if (isCancelledError(error)) {
              return;
            }

            if (
              // Don't try to access `window` if it's server-side rendering.
              typeof window !== "undefined" &&
              // Or if we're running in jsdom...
              typeof process === "undefined" &&
              // Don't render toasts on public pages.
              isPublicPage(window.location.pathname)
            ) {
              return;
            }

            if (shouldRelogin(error)) {
              relogin();
              return;
            }

            if (
              isTrpcProjectSessionExpiredError(error) ||
              isProjectSessionExpiredError(error)
            ) {
              ProjectSessionExpiredModal.open();

              return;
            }

            if (isObject(query.meta)) {
              // No toast if `meta.noToast` is true.
              if (query.meta.noToast === true) {
                return;
              }

              // No toast if `meta.noToastForCode` contains the error code.
              if (
                query.meta.noToastForCode instanceof Array &&
                error instanceof TRPCClientError
              ) {
                if (query.meta.noToastForCode.includes(error.data?.code)) {
                  return;
                }
              }

              // No toast if `meta.noToast` is a function that returns true when
              // passed the error.
              if (isFunction(query.meta.noToast)) {
                if (query.meta.noToast(error)) return;
              }
            }

            // No toast if error code is "tables:permissions:errors"
            if (
              error instanceof TRPCClientError &&
              error.data?.code === "tables:permissions:errors"
            ) {
              return;
            }

            const errorMessageOverride =
              isObject(query.meta) && isString(query.meta.errorMessage)
                ? query.meta.errorMessage
                : undefined;

            toastFriendlyErrorMessage(error, errorMessageOverride);

            // see: https://tkdodo.eu/blog/react-query-error-handling#putting-it-all-together
            // 🎉 only show error toasts if we already have data in the cache
            // which indicates a failed background update
            // if (query.state.data !== undefined) {
            //   toast.error(`Something went wrong: ${error.message}`)
            // }
          },
        }),
      },
    };
  },
  ssr: false,
});

/**
 * Vanilla tRPC client for making requests outside of React components. Bear in
 * mind that this client will not have access to the React Query cache. For that
 * and for making requests in React components with hooks, use `trpc` from this
 * module.
 *
 * see https://trpc.io/docs/client/vanilla
 */
export const trpcClient: CreateTRPCProxyClient<AppRouter> =
  createTRPCProxyClient<AppRouter>({
    links: linksConfig(),
  });

/**
 * Configure tRPC links
 *
 * see https://trpc.io/docs/client/links
 *
 * @param ctx (optional) Next.js context
 */
function linksConfig(ctx?: NextPageContext) {
  return [
    httpLink({
      url: `${getBaseUrl()}/api/trpc`,
      headers() {
        const additional: HTTPHeaders = {};

        const ddRumContext = datadogRum.getInternalContext();
        if (ddRumContext?.session_id) {
          additional["x-dd-rum-session-id"] = ddRumContext.session_id;
        }

        // TODO: with ssr: false below, I believe this won't ever actually fire on the server side
        if (ctx?.req) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { connection: _connection, ...headers } = ctx.req.headers;
          return { ...headers, ...additional, "x-ssr": "1" };
        }
        return { ...additional };
      },
      fetch: fetchWithProjectSession as FetchEsque,
    }),
  ];
}

function getBaseUrl() {
  if (config().NODE_ENV === "test") return "http://localhost:3999";

  if (typeof window !== "undefined") {
    return "";
  }

  /**
   * TODO: Is using the vercel URL here causing problems during server render?
   */
  // reference for vercel.com
  if (process.env.VERCEL_URL) {
    return `https://${process.env.VERCEL_URL}`;
  }

  // assume localhost
  return `http://localhost:${process.env.PORT ?? 3000}`;
}

type RouterOutputs = inferRouterOutputs<AppRouter>;
type RouterInputs = inferRouterInputs<AppRouter>;

export type ChatbotsCreateEvent = RouterInputs["chatbots"]["createEvent"];

export type Project = ProjectWithExtra;
export type Page = RouterOutputs["pages"]["get"];
export type SplitCheck = RouterOutputs["split"]["check"];
export type ListListingPageResponse =
  RouterOutputs["projects"]["listListingPage"];
export type Zap = RouterOutputs["zaps"]["search"][number];
export type Table = RouterOutputs["tableTables"]["list"]["data"][number];
export type ConsumerMe = RouterOutputs["consumers"]["me"];
export type CreateZapPayload = RouterInputs["zaps"]["create"];
export type UpdateProjectProps = RouterInputs["projects"]["update"];
export type UpdatePageProps = RouterInputs["pages"]["update"];
export type CreatePageProps = RouterInputs["pages"]["create"];
export type ProjectList =
  RouterOutputs["projects"]["listListingPage"]["projects"];
export type ProjectListItem = ProjectList[number];
export type ProjectListProject = ProjectList[number]["project"];
export type Collaborator = RouterOutputs["collaborators"]["list"][number];
export type ProjectCountOutput = RouterOutputs["projects"]["count"];
export type ChatbotKnowledgeSource =
  RouterOutputs["chatbots"]["getChatbotKnowledgeSources"][0];
export type LegacyKnowledgeSource = ChatbotKnowledgeSource & {
  description: string;
  dataType: "file" | "table";
  splitStrategy: "auto";
  splitValue: "";
  chunks: [];
};
export type AccountOverrideWithProjectCount =
  RouterOutputs["admin"]["listAccountOverrides"][number];
export type PaidFeature =
  AccountOverrideWithProjectCount["paidFeatureAccess"][number];
export type CreateChatbotEventInput = RouterInputs["chatbots"]["createEvent"];
export type CreateChatbotEventOutput = RouterOutputs["chatbots"]["createEvent"];
