import { InterfacesTheme } from "../../schema";
import { adjustColor } from "./brandedTheme-helpers";

export type Theme = Pick<
  InterfacesTheme,
  | "foreground"
  | "cardForeground"
  | "darkForeground"
  | "darkCardForeground"
  | "primary"
  | "darkPrimary"
  | "secondaryForeground"
  | "darkSecondaryForeground"
  | "background"
  | "darkBackground"
  | "brandColor"
>;

export type DerivedColorName = keyof ReturnType<typeof getDerivedColors>;

export function getDerivedColors(theme: Theme) {
  const WHITE_HEX = "#FFFFFF";

  return {
    /**
     * Values based on https://www.figma.com/design/7snwcEg5dFAx9d6pIiNNDP/branch/hMV8rYjbOnzO8dSduiWfLf/Components-library?node-id=2345-2499&t=4RE8fEZpEzjUaKj6-0
     */
    disabledPrimaryButtonText: adjustColor(theme.primary, {
      saturation: -20,
      lightness: +35,
    }),
    disabledPrimaryButtonBackground: adjustColor(theme.primary, {
      saturation: -40,
      lightness: +80,
    }),
    disabledSecondaryButtonText: adjustColor(theme.cardForeground, {
      saturation: -60,
      lightness: +60,
    }),
    disabledSecondaryButtonBackground: adjustColor(WHITE_HEX, {
      lightness: -8,
    }),
    disabledOutlineButtonText: adjustColor(theme.cardForeground, {
      saturation: -60,
      lightness: +60,
    }),
    disabledOutlineButtonBackground: adjustColor(WHITE_HEX, {
      lightness: -8,
    }),
    disabledGhostButtonText: adjustColor(theme.cardForeground, {
      saturation: -60,
      lightness: +60,
    }),
    disabledGhostButtonBackground: adjustColor(WHITE_HEX, {
      lightness: -8,
    }),
    disabledDestructiveButtonText: adjustColor(theme.cardForeground, {
      saturation: -60,
      lightness: +60,
    }),
    disabledDestructiveButtonBackground: adjustColor(WHITE_HEX, {
      lightness: -8,
    }),

    /**
     * Values based on https://zapier.slack.com/archives/C081QCT7JTY/p1740992994671129?thread_ts=1733401017.614609&cid=C081QCT7JTY
     */
    foregroundSubtle: adjustColor(theme.foreground, {
      saturation: -60,
      lightness: +30,
    }),
    cardForegroundSubtle: adjustColor(theme.cardForeground, {
      saturation: -60,
      lightness: +30,
    }),

    /**
     * Values based on https://zapier.slack.com/archives/C081QCT7JTY/p1740992994671129?thread_ts=1733401017.614609&cid=C081QCT7JTY
     */
    darkForegroundSubtle: adjustColor(theme.darkForeground, {
      saturation: -60,
      lightness: -20,
    }),
    darkCardForegroundSubtle: adjustColor(theme.darkCardForeground, {
      saturation: -60,
      lightness: -20,
    }),

    /**
     * Values based on https://www.figma.com/design/7snwcEg5dFAx9d6pIiNNDP/branch/hMV8rYjbOnzO8dSduiWfLf/Components-library?node-id=2345-2499&t=4RE8fEZpEzjUaKj6-0
     */
    darkDisabledPrimaryButtonText: adjustColor(theme.darkPrimary, {
      saturation: -80,
      lightness: -80,
    }),
    darkDisabledPrimaryButtonBackground: adjustColor(theme.darkPrimary, {
      saturation: -85,
      lightness: -55,
    }),
    darkDisabledSecondaryButtonText: adjustColor(theme.darkPrimary, {
      saturation: -95,
      lightness: -80,
    }),
    darkDisabledSecondaryButtonBackground: adjustColor(theme.darkPrimary, {
      saturation: -85,
      lightness: -55,
    }),
    darkDisabledOutlineButtonText: adjustColor(theme.darkPrimary, {
      saturation: -95,
      lightness: -80,
    }),
    darkDisabledOutlineButtonBackground: adjustColor(theme.darkPrimary, {
      saturation: -85,
      lightness: -55,
    }),
    darkDisabledGhostButtonText: adjustColor(theme.darkPrimary, {
      saturation: -95,
      lightness: -80,
    }),
    darkDisabledGhostButtonBackground: adjustColor(theme.darkPrimary, {
      saturation: -85,
      lightness: -55,
    }),
    darkDisabledDestructiveButtonText: adjustColor(theme.darkPrimary, {
      saturation: -95,
      lightness: -80,
    }),
    darkDisabledDestructiveButtonBackground: adjustColor(theme.darkPrimary, {
      saturation: -85,
      lightness: -55,
    }),
  };
}
