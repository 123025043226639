import type { useTheme } from "lib/theme";
import {
  HslTheme,
  InterfacesThemeSchema,
  ThemeModeSchema,
  type InterfacesTheme,
} from "lib/theme/schema";
import { defaultCustomizableThemeColors } from "lib/theme/themes/app-theme";
import { merge } from "lodash";
import {
  ReactNode,
  createContext,
  memo,
  useCallback,
  useContext,
  useDeferredValue,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { ProjectAppearance } from "server/schemas/projects";
import { ThemeProvider as SCThemeProvider } from "styled-components";
import { toTwHsl } from "./tailwind-palette-generator";
import getTheme from "./themes";
import { SCHEMES } from "./use-color-scheme";
import { extractShadcnColors, ShadcnColorName } from "./themes/v2-theme/utils";
import {
  getHslThemeCssProperties,
  HslThemeNestedSelectors,
} from "./hsl-theme-css-properties";
import {
  DerivedColorName,
  getDerivedColors,
} from "./themes/v2-theme/derivedColors";

export type SetInterfacesTheme = (
  theme: Partial<InterfacesTheme> | null
) => void;

const InterfacesThemeContext = createContext<
  InterfacesTheme | null | undefined
>(undefined);

const SetInterfacesThemeContext = createContext<SetInterfacesTheme | undefined>(
  undefined
);

export function ThemeProvider({
  children,
  projectAppearance,
  allowSystemThemeMode = false,
  ...props
}: {
  children: ReactNode;
  /**
   * The `null` | `undefined` types here is on purpose and they are REQUIRED on purpose.
   * We had quite a few bugs, were we forgot to pass the `projectAppearance` because it was optional.
   */
  projectAppearance: ProjectAppearance | undefined | null;
  interfacesTheme: Partial<InterfacesTheme> | undefined | null;
  allowSystemThemeMode?: boolean;
}) {
  const { theme, resetTheme, setTheme } = useThemeState({ projectAppearance });
  const deferredTheme = useDeferredValue(theme);

  const [interfacesTheme, setInterfacesTheme] = useInitInterfacesTheme(
    props.interfacesTheme
  );

  const previewProviderValue = useMemo(() => {
    return {
      setTheme,
      resetTheme,
    };
  }, [resetTheme, setTheme]);

  return (
    <SetInterfacesThemeContext.Provider value={setInterfacesTheme}>
      <InterfacesThemeContext.Provider value={interfacesTheme}>
        <PreviewThemeContext.Provider value={previewProviderValue}>
          {/* The component you pass the deferred value to must be memoized.
      https://react.dev/reference/react/useDeferredValue#deferring-re-rendering-for-a-part-of-the-ui */}
          <MemoizedSCThemeProvider
            deferredTheme={deferredTheme}
            pageWidth={theme.app.pageWidth}
            allowSystemThemeMode={allowSystemThemeMode}
          >
            {children}
          </MemoizedSCThemeProvider>
        </PreviewThemeContext.Provider>
      </InterfacesThemeContext.Provider>
    </SetInterfacesThemeContext.Provider>
  );
}

const MemoizedSCThemeProvider = memo(function MemoizedSCThemeProvider({
  deferredTheme,
  pageWidth,
  children,
  allowSystemThemeMode,
}: {
  deferredTheme: ReturnType<typeof getTheme>;
  pageWidth: number;
  children: ReactNode;
  allowSystemThemeMode: boolean;
}) {
  const hslTheme = useHslTheme();

  function renderBaseCssProperties() {
    if (!hslTheme) return null;

    return (
      <style global jsx>{`
        :root {
          --zi-pageWidth: ${pageWidth}px;
          --radius: ${hslTheme.radius};
        }
      `}</style>
    );
  }

  function renderColorCssProperties() {
    if (!hslTheme) return null;

    const { mode } = hslTheme;

    switch (mode) {
      case ThemeModeSchema.enum.Light:
        return (
          <style global jsx>{`
            ${getHslThemeCssProperties([":root"], hslTheme, "Light")}
          `}</style>
        );
      case ThemeModeSchema.enum.Dark:
        return (
          <style global jsx>{`
            ${getHslThemeCssProperties([":root"], hslTheme, "Dark")}
          `}</style>
        );
      case ThemeModeSchema.enum.System:
        const darkQualifiers: HslThemeNestedSelectors = allowSystemThemeMode
          ? ["@media (prefers-color-scheme: dark)", ":root"]
          : [".dark"];

        return (
          <style global jsx>{`
            ${getHslThemeCssProperties([":root"], hslTheme, "Light")}
            ${getHslThemeCssProperties(darkQualifiers, hslTheme, "Dark")}
          `}</style>
        );
      default: {
        const _exhaustiveCheck: never = mode;
        return _exhaustiveCheck;
      }
    }
  }

  return (
    <SCThemeProvider theme={deferredTheme}>
      {renderBaseCssProperties()}
      {renderColorCssProperties()}
      {children}
    </SCThemeProvider>
  );
});

type PreviewThemeProps = {
  setTheme: (theme: ReturnType<typeof getTheme>) => void;
  resetTheme: VoidFunction;
};

const PreviewThemeContext = createContext<PreviewThemeProps | null>(null);

export function usePreviewTheme() {
  const providerValue = useContext(PreviewThemeContext);
  if (!providerValue) {
    throw Error("Preview theme provider value should be present");
  }

  return providerValue;
}

export function buildTheme({
  projectAppearance,
  currentTheme,
}: {
  projectAppearance: ProjectAppearance;
  currentTheme: ReturnType<typeof useTheme>;
}) {
  const userAppearanceSetting = {
    app: {
      colors: {
        ...defaultCustomizableThemeColors,
        ...projectAppearance,
        themeBackground:
          projectAppearance?.pageBackground ??
          currentTheme.app.colors.themeBackground,
        pageBackground:
          projectAppearance?.pageBackground ??
          currentTheme.app.colors.themeBackground,
      },
    },
  };

  /**
   * The `merge` will mutate the first object, so we need to pass an empty object as the first argument.
   */
  return merge({}, currentTheme, userAppearanceSetting);
}

const buildInitialTheme = ({
  projectAppearance = {},
  scheme,
}: {
  projectAppearance?: ProjectAppearance;
  scheme: SCHEMES;
}) => {
  const currentTheme = getTheme(scheme);
  return buildTheme({ projectAppearance, currentTheme });
};

const scheme: SCHEMES = SCHEMES.LIGHT;

function useThemeState({
  projectAppearance,
}: {
  projectAppearance: ProjectAppearance | undefined;
}) {
  const [theme, setTheme] = useState(() => {
    return buildInitialTheme({
      projectAppearance,
      scheme,
    });
  });

  /**
   * This ensures the `resetTheme` is stable, even if the `projectAppearance` changes.
   * We need it to be stable, to reset the theme when the "Customize colors" form unmounts.
   *
   * This could happen in two cases:
   * 1. The user clicks the "X" on the drawer.
   * 2. The user focuses a block within the main builder when the drawer is open.
   *
   * The second case does not trigger onClose event, so we need to reset the theme when the form unmounts.
   */
  const latestProjectAppearance = useRef(projectAppearance);
  useEffect(() => {
    latestProjectAppearance.current = projectAppearance;
  }, [projectAppearance]);

  const resetTheme = useCallback(() => {
    const theme = buildInitialTheme({
      projectAppearance: latestProjectAppearance.current,
      scheme,
    });

    setTheme(theme);
  }, []);

  return { theme, setTheme, resetTheme };
}

function useInitInterfacesTheme(theme?: Partial<InterfacesTheme> | null) {
  const [interfacesTheme, setInterfacesTheme] =
    useState<InterfacesTheme | null>(() => {
      if (!theme) {
        return null;
      }

      return InterfacesThemeSchema.parse(theme);
    });

  const applyInterfacesTheme = useCallback(
    (theme: Partial<InterfacesTheme> | null) => {
      const parsedTheme = theme ? InterfacesThemeSchema.parse(theme) : null;
      setInterfacesTheme(parsedTheme);
    },
    []
  );

  useEffect(() => {
    if (theme) {
      setInterfacesTheme(InterfacesThemeSchema.parse(theme));
    }
  }, [theme]);

  return [interfacesTheme, applyInterfacesTheme] as const;
}

export function useInterfacesTheme() {
  const theme = useContext(InterfacesThemeContext);
  if (theme === undefined) {
    throw new Error("useInterfacesTheme must be used within a ThemeProvider");
  }

  return theme;
}

function getHslTheme(interfacesTheme: Partial<InterfacesTheme>): HslTheme {
  const parsedTheme = InterfacesThemeSchema.parse(interfacesTheme);
  const shadcnColors = extractShadcnColors(parsedTheme);
  const derivedColors = getDerivedColors(parsedTheme);
  const hexColors = {
    ...shadcnColors,
    ...derivedColors,
  };

  const hslColors = Object.entries(hexColors).reduce<
    Pick<HslTheme, ShadcnColorName | DerivedColorName>
  >((acc, [colorName, hexColor]) => {
    const hsl = toTwHsl(hexColor).split(" / ").shift();
    if (hsl) {
      acc[colorName as ShadcnColorName] = hsl;
    }
    return acc;
  }, {} as HslTheme);

  return {
    ...hslColors,
    brandColor: parsedTheme.brandColor,
    radius: parsedTheme.radius,
    mode: parsedTheme.mode ?? ThemeModeSchema.enum.Light,
  };
}

function useHslTheme() {
  const interfacesTheme = useInterfacesTheme();

  return useMemo(() => {
    return interfacesTheme ? getHslTheme(interfacesTheme) : null;
  }, [interfacesTheme]);
}

export function useSetInterfacesTheme() {
  const setInterfacesTheme = useContext(SetInterfacesThemeContext);
  if (setInterfacesTheme === undefined) {
    throw new Error(
      "useSetInterfacesTheme must be used within a ThemeProvider"
    );
  }

  return setInterfacesTheme;
}

export type { InterfacesTheme };
