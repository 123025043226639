import { toast } from "@/components/Toaster";
import { isApiErrorResponse } from "@/server/openapi/errors";
import {
  STYTCH_EMAIL_NOT_EXPLICITLY_ALLOWED_ERROR_CODE,
  STYTCH_EMAIL_NOT_IN_ALLOWLIST_ERROR_CODE,
  STYTCH_INVALID_DOMAIN_ERROR_CODE,
  STYTCH_ORGANIZATION_SLUG_ALREADY_USED_ERROR_CODE,
  STYTCH_DUPLICATE_MEMBER_EMAIL_ERROR_CODE,
} from "@/server/types/errors";
import { TRPCClientError } from "@trpc/client";
import { get, isString } from "lodash";

export function getFriendlyErrorMessage(error: unknown): string {
  if (error instanceof TRPCClientError) {
    const code = isString(error.data?.code)
      ? String(get(error.data, "code"))
      : "unknown";

    // we'll try to generically handle all OpenAI errors
    if (code.startsWith("openai:")) {
      return (
        "We couldn’t complete your request because of the OpenAI issue(s) below.\n\n" +
        (error.data.serviceMessage ??
          `Status: ${error.data.serviceResponseStatus}`)
      );
    } else {
      switch (code) {
        case "external:timeout":
          return "A third-party service took too long to respond.";
        case "interfaces:domain-in-use":
          return "That custom domain is already claimed by another Interface.";
        case "vercel:invalid_domain":
          return "That domain is invalid.";
        case "tables:limits:too_many_tables":
          return "You have reached the maximum number of tables allowed in your account. Please upgrade your account to add more tables.";
        case "tables:limits:too_many_fields":
          return "You have reached the maximum number of fields allowed in your account. Please upgrade your account to add more fields.";
        case "tables:limits:too_many_records":
          return "You have reached the maximum number of records allowed in your account. Please upgrade your account to add more records.";
        case "tables:permissions:errors":
          return "This page is connected to a Table you don't have access to.";
        case "block:chatbot:directive-too-long":
          return "Chatbot directive is too long.";
        case "interfaces:limits:projects":
          return "You have reached the limit of Interfaces for your plan. Please upgrade your plan to create more.";
        case "interfaces:limits:blocks-per-page":
          return "You have reached the maximum number of components that can be added to a page. Please consider creating a new page.";
      }
    }
  }

  let message = "Uh oh, something went wrong.";

  if (isApiErrorResponse(error)) {
    switch (error.code) {
      case STYTCH_EMAIL_NOT_IN_ALLOWLIST_ERROR_CODE:
      case STYTCH_EMAIL_NOT_EXPLICITLY_ALLOWED_ERROR_CODE:
        return "This email has not been added to this interface yet. Please contact your administrator.";
      case STYTCH_INVALID_DOMAIN_ERROR_CODE:
        return "Invalid domain.";
      case STYTCH_ORGANIZATION_SLUG_ALREADY_USED_ERROR_CODE:
        /**
         * Since the fact that we create a Stytch organization is hidden from the user,
         * the message here is vague.
         */
        return "An error occurred. Please try again.";
      case STYTCH_DUPLICATE_MEMBER_EMAIL_ERROR_CODE:
        return "This email is already in use.";
      default: {
        return `${message}\n${error.detail}`;
      }
    }
  }

  if (error instanceof Error && error.message) {
    message += `\n\`${error.message}\``;
  }

  return message;
}

export function getFriendlyErrorMessageWithErrorCode(error: unknown): string {
  let message: string;
  message = getFriendlyErrorMessage(error);

  const formattedErrorId = extractErrorId(error);
  if (formattedErrorId) message += `\n${formattedErrorId}`;

  return message;
}

export function toastFriendlyErrorMessage(
  error: unknown,
  errorMessageOverride?: string
) {
  let baseMessage: string, msgWithErrorCode: string;

  if (errorMessageOverride) {
    baseMessage = errorMessageOverride;
    msgWithErrorCode = errorMessageOverride;
  } else {
    baseMessage = getFriendlyErrorMessage(error);
    msgWithErrorCode = getFriendlyErrorMessageWithErrorCode(error);
  }

  return toast.error(
    {
      message: msgWithErrorCode,
    },
    {
      id: baseMessage,
      duration: Infinity,
    }
  );
}

export function extractErrorId(error: unknown): string | undefined {
  const errorId = get(error, ["data", "id"]) as string | undefined;
  if (errorId) return `\`«${errorId}»\``;
}
