import { TRPCClientError } from "@trpc/client";
import { TRPC_ERROR_CODE_KEY } from "@trpc/server/rpc";
import cuid from "cuid";
import { isObject } from "lodash";
import { ZodError, ZodIssue } from "zod";

export const UNKNOWN_ERROR_CODE = "unknown-error";
export const UNKNOWN_ERROR_STATUS = 500;
export const UNKNOWN_ERROR_MSG = "An unexpected error occurred";

export interface AppErrorArgs {
  code?: string;
  status?: number;
  message?: string;
  cause?: unknown;
  data?: Record<string, any>;
}

export class AppError extends Error {
  public name = "AppError";
  public code: string;
  public status: number;
  public id = cuid.slug();
  public trpcCode: TRPC_ERROR_CODE_KEY = "INTERNAL_SERVER_ERROR";
  public data: Record<string, any> = {};
  public cause?: unknown;

  constructor({ code, status, message, cause, data }: AppErrorArgs = {}) {
    if (!message) {
      if (cause instanceof Error || isZodIssue(cause)) {
        message = cause.message;
      } else {
        message = UNKNOWN_ERROR_MSG;
      }
    }
    super(message, { cause });

    this.code = code ?? codeFromError(cause);
    this.status = status ?? UNKNOWN_ERROR_STATUS;
    this.trpcCode = trpcCodeFromStatus(this.status);
    this.addData(data ?? {});
  }

  public addData(data: Record<string, any>) {
    this.data = { ...this.data, ...data };
  }

  public toJSON() {
    return {
      id: this.id,
      name: this.name,
      status: this.status,
      code: this.code,
      message: this.message,
      data: this.data,
      cause: this.cause,
    };
  }
}

/**
 * This error indicates that the user is not logged in to the Zapier monolith, and
 * must be redirected to the login page.
 */
export class NotLoggedInError extends AppError {
  name = "NotLoggedInError";
  code = "auth:not-logged-in";
  status = 401;

  constructor() {
    super({ message: "not logged in" });
  }
}

export class ProjectSessionExpiredErrorTrpc extends AppError {
  name = "ProjectSessionExpiredError";
  code = "auth:project-session-expired";
  status = 401;

  constructor() {
    super({
      message: "Project session expired",
    });
  }
}

export function isTrpcProjectSessionExpiredError(error: unknown) {
  if (error instanceof TRPCClientError && error.data?.code) {
    switch (error.data.code) {
      case "auth:project-session-expired":
        return true;
    }
  }

  return false;
}

export class DomainInUseError extends AppError {
  name = "DomainInUseError";
  code = "interfaces:domain-in-use";
  status = 400;

  constructor() {
    super({
      message:
        "That custom domain is already claimed by another Interfaces project.",
    });
  }
}

// Technically 408 is sent by server on idle connection. But we are using it here to represent a timeout error.
// If this is changed to a different status code, the retryable error codes should be updated as well.
export class AbortError extends AppError {
  constructor() {
    super({ status: 408, message: "Request Timeout" });
  }
}

export class SlugInUseError extends AppError {
  name = "SlugInUseError";
  code = "interfaces:slug-in-use";
  status = 400;

  constructor() {
    super({
      message: "That slug is not available.",
    });
  }
}

// TODO: get rid of this class, it is redundant over AppError now
export class ExposableError extends AppError {
  name = "ExposableError";
  code = "exposable-error";

  constructor(
    public errors: string[],
    public cause?: Error
  ) {
    super({ cause });
    this.addData({ errors });
  }
}

export const RETRYABLE_ERROR_CODES = [408, 429, 500, 502, 503, 504];
const HTTP_STATUS_TO_JSONRPC2: Record<number, TRPC_ERROR_CODE_KEY> = {
  400: "BAD_REQUEST",
  404: "NOT_FOUND",
  500: "INTERNAL_SERVER_ERROR",
  401: "UNAUTHORIZED",
  403: "FORBIDDEN",
  408: "TIMEOUT",
  409: "CONFLICT",
  499: "CLIENT_CLOSED_REQUEST",
  412: "PRECONDITION_FAILED",
  413: "PAYLOAD_TOO_LARGE",
  405: "METHOD_NOT_SUPPORTED",
  429: "TOO_MANY_REQUESTS",
};

const trpcCodeFromStatus = (status: number): TRPC_ERROR_CODE_KEY =>
  HTTP_STATUS_TO_JSONRPC2[status] ?? "INTERNAL_SERVER_ERROR";

function codeFromError(err: unknown): string {
  if (err instanceof AppError) {
    return err.code;
  }

  if (err instanceof Error && err.hasOwnProperty("code")) {
    return (err as any).code;
  }

  if (err instanceof ZodError) {
    return "app:validation";
  }

  if (isZodIssue(err)) {
    return err.code;
  }

  return UNKNOWN_ERROR_CODE;
}

export function isZodIssue(e: unknown): e is ZodIssue {
  if (!isObject(e)) return false;

  return (
    "message" in e &&
    typeof e.message === "string" &&
    "path" in e &&
    Array.isArray(e.path) &&
    e.path.every((p) => typeof p === "string" || typeof p === "number")
  );
}

/**
 * The codes live in a separate from "stytch.ts" service file so that we can import them into frontend code
 * without causing a dependency on a node-only package.
 */

/**
 * The org has domains whitelisted but the email is not in the whitelist.
 */
export const STYTCH_EMAIL_NOT_IN_ALLOWLIST_ERROR_CODE =
  "stytch:invalid_email_for_jit_provisioning";

/**
 * The org does not have any domains whitelisted and the email is not explicitly invited.
 */
export const STYTCH_EMAIL_NOT_EXPLICITLY_ALLOWED_ERROR_CODE =
  "stytch:email_not_explicitly_allowed";

export const STYTCH_DUPLICATE_MEMBER_EMAIL_ERROR_CODE =
  "stytch:duplicate_member_email";

export const STYTCH_ORGANIZATION_SLUG_ALREADY_USED_ERROR_CODE =
  "stytch:organization_slug_already_used";

export const STYTCH_INVALID_DOMAIN_ERROR_CODE =
  "stytch:organization_settings_invalid_domain";
