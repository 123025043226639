import { darkifyName } from "@/components/ProjectSettings/Appearance/utils";
import { HslTheme } from "./schema";

// Explicit allow list of nested selectors because typescript cannot validate
// all possible nested selectors.
export type HslThemeNestedSelectors =
  | [":root"]
  | ["@media (prefers-color-scheme: dark)", ":root"]
  | [".dark"];

export function getHslThemeCssProperties(
  nestedSelectors: HslThemeNestedSelectors,
  hslTheme: HslTheme,
  mode: "Light" | "Dark"
): string {
  return `
    ${nestedSelectors.map((qualifier) => `${qualifier} {`).join("")}
      --background: ${hslTheme[darkifyName(mode, "background")]};
      --foreground: ${hslTheme[darkifyName(mode, "foreground")]};
      --foreground-subtle: ${hslTheme[darkifyName(mode, "foregroundSubtle")]};

      --card: ${hslTheme[darkifyName(mode, "card")]};
      --card-foreground: ${hslTheme[darkifyName(mode, "cardForeground")]};
      --card-foreground-subtle: ${hslTheme[darkifyName(mode, "cardForegroundSubtle")]};

      --popover: ${hslTheme[darkifyName(mode, "popover")]};
      --popover-foreground: ${hslTheme[darkifyName(mode, "popoverForeground")]};

      --primary: ${hslTheme[darkifyName(mode, "primary")]};
      --primary-foreground: ${hslTheme[darkifyName(mode, "primaryForeground")]};

      --secondary: ${hslTheme[darkifyName(mode, "secondary")]};
      --secondary-foreground: ${hslTheme[darkifyName(mode, "secondaryForeground")]};

      --muted: ${hslTheme[darkifyName(mode, "muted")]};
      --muted-foreground: ${hslTheme[darkifyName(mode, "mutedForeground")]};

      --accent: ${hslTheme[darkifyName(mode, "accent")]};
      --accent-foreground: ${hslTheme[darkifyName(mode, "accentForeground")]};

      --destructive: ${hslTheme[darkifyName(mode, "destructive")]};
      --destructive-foreground: ${hslTheme[darkifyName(mode, "destructiveForeground")]};

      --border: ${hslTheme[darkifyName(mode, "border")]};
      --input: ${hslTheme[darkifyName(mode, "input")]};
      --ring: ${hslTheme[darkifyName(mode, "ring")]};

      --disabled-primary-button-text: ${hslTheme[darkifyName(mode, "disabledPrimaryButtonText")]};
      --disabled-primary-button-background: ${hslTheme[darkifyName(mode, "disabledPrimaryButtonBackground")]};
      --disabled-secondary-button-text: ${hslTheme[darkifyName(mode, "disabledSecondaryButtonText")]};
      --disabled-secondary-button-background: ${hslTheme[darkifyName(mode, "disabledSecondaryButtonBackground")]};
      --disabled-outline-button-text: ${hslTheme[darkifyName(mode, "disabledOutlineButtonText")]};
      --disabled-outline-button-background: ${hslTheme[darkifyName(mode, "disabledOutlineButtonBackground")]};
      --disabled-ghost-button-text: ${hslTheme[darkifyName(mode, "disabledGhostButtonText")]};
      --disabled-ghost-button-background: ${hslTheme[darkifyName(mode, "disabledGhostButtonBackground")]};
      --disabled-destructive-button-text: ${hslTheme[darkifyName(mode, "disabledDestructiveButtonText")]};
      --disabled-destructive-button-background: ${hslTheme[darkifyName(mode, "disabledDestructiveButtonBackground")]};

    ${nestedSelectors.map(() => `}`).join("")}
  `;
}
